import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import DashboardView from '@/views/Dashboard/DashboardView.vue';
// import ForgetPasswordView from '@/views/ForgetPasswordView.vue';
import FeaturesSetting from '@/views/Features/FeaturesSetting.vue';
import FeaturesForm from '@/views/Features/Components/Form.vue';
import Product from '@/views/Product/ProductView.vue';
import ProductForm from '@/views/Product/Components/Form.vue';
import Customer from '@/views/Customer/CustomerView.vue';
import CustomerForm from '@/views/Customer/Components/Form.vue';
import Sale from '@/views/Sale/SaleView.vue';
// import Order from '@/views/Order/OrderView.vue';
// import OrderForm from '@/views/Order/Components/Form.vue';
import OrderItem from '@/views/OrderItem/OrderItemView.vue';
import Stock from '@/views/Stock/StockView.vue';
import StockSummary from '@/views/Stock/Components/StockSummary.vue';
import Setting from '@/views/Settings/SettingView.vue';
import SecuritySetting from '@/views/SecuritySetting/SettingView.vue';
import User from '@/views/Users/UserView.vue';
import Warranty from '@/views/Warranty/WarrantySetting.vue';
// import Due from '@/views/Due/DueView.vue';
// import DueForm from '@/views/Due/Components/Form.vue';
import Supplier from '@/views/Supplier/SupplierView.vue';
import SupplierForm from '@/views/Supplier/Components/Form.vue';
import Type from '@/views/Type/TypeView.vue';
// import TypeForm from '@/views/Type/Components/Form.vue';
// import Payment from '@/views/Payments/PaymentView.vue';
import BarcodeView from '@/views/BarcodePage/Barcode.vue';
import Store from '@/views/Store/StoreView.vue';
import StoreForm from '@/views/Store/Components/Form.vue';
import Invoice from '@/views/InvoiceView.vue';
import NotFound from '@/views/NotFound.vue';
import { useStore } from 'vuex';
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Dashboard',
    component: DashboardView,
  },
  {
    path: '/features_setting',
    name: 'features-setting',
    component: FeaturesSetting,
    meta: { showSpinner: true }
  },
  {
    path: '/features_setting/form/:id?',
    name: 'features-form',
    component: FeaturesForm,
    meta: { showSpinner: true }
  },
  {
    path: '/product',
    name: 'product-view',
    component: Product,
    meta: { showSpinner: true },
    
  },
  {
    path: '/product/form/:id?',
    name: 'product-form',
    component: ProductForm,
    meta: { showSpinner: true },
    
  },
  {
    path: '/barcode',
    name: 'barcode-view',
    component: BarcodeView,
    meta: { showSpinner: true },
    
  },
  {
    path: '/barcode/form/:id?',
    name: 'barcode-summary',
    component: ProductForm,
    meta: { showSpinner: true },
    
  },
  {
    path: '/customer',
    name: 'customer-view',
    component: Customer,
    meta: { showSpinner: true }
  },
  {
    path: '/customer/form/:id?',
    name: 'customer-form',
    component: CustomerForm,
    meta: { showSpinner: true }
  },
  {
    path: '/sale/:id?',
    name: 'sale-view',
    component: Sale,
    meta: { showSpinner: true }
  },
  // {
  //   path: '/order',
  //   name: 'order-view',
  //   component: Order,
  //   meta: { showSpinner: true }
  // },
  // {
  //   path: '/order/form/:id?',
  //   name: 'order-form',
  //   component: OrderForm,
  //   meta: { showSpinner: true }
  // },
  {
    path: '/order_item',
    name: 'Order-item-view',
    component: OrderItem,
    meta: { showSpinner: true }
  },
  {
    path: '/stock',
    name: 'stock-view',
    component: Stock,
    meta: { showSpinner: true }
  },
  {
    path: '/stock/summary/:id?',
    name: 'stock-summary',
    component: StockSummary,
    meta: { showSpinner: true }
  },
  {
    path: '/setting',
    name: 'setting-view',
    component: Setting,
    meta: { showSpinner: true }
  },
  {
    path: '/securitysettings',
    name: 'security-setting-view',
    component: SecuritySetting,
    meta: { showSpinner: true }
  },
  {
    path: '/users',
    name: 'user-view',
    component: User,
    meta: { showSpinner: true }
  },
  // {
  //   path: '/due',
  //   name: 'due-view',
  //   component: Due,
  //   meta: { showSpinner: true }
  // },
  // {
  //   path: '/due/form/:id?',
  //   name: 'due-form',
  //   component: DueForm,
  //   meta: { showSpinner: true }
  // },
  {
    path: '/supplier',
    name: 'supplier-view',
    component: Supplier,
    meta: { showSpinner: true }
  },
  {
    path: '/supplier/form/:id?',
    name: 'supplier-form',
    component: SupplierForm,
    meta: { showSpinner: true }
  },
  {
    path: '/warranty',
    name: 'warranty-setting',
    component: Warranty,
    meta: { showSpinner: true }
  },
  // {
  //   path: '/type',
  //   name: 'type-view',
  //   component: Type,
  //   meta: { showSpinner: true },    
  //   beforeEnter: (to, from, next) => {
  //     const userData = localStorage.getItem('userData') || '{}';
  //     const parsedUserData = JSON.parse(userData);
  //     console.log(parsedUserData.type);      
  //     if (parsedUserData.type === 'SUPERADMIN' || to.matched.some(record => record.meta.requiresSuperAdmin)) {
  //       // console.log('Access grated');
  //       next();
  //     } else {
  //       // console.log('Access denied');
  //       next('/');
  //     }
  //   },
      
  // },
  // {
  //   path: '/type/form/:id?',
  //   name: 'tyoe-form',
  //   component: Type,
  //   meta: { showSpinner: true },    
  //   beforeEnter: (to, from, next) => {
  //     const userData = localStorage.getItem('userData') || '{}';
  //     const parsedUserData = JSON.parse(userData);
  //     console.log(parsedUserData.type);      
  //     if (parsedUserData.type === 'SUPERADMIN' || to.matched.some(record => record.meta.requiresSuperAdmin)) {
  //       // console.log('Access grated');
  //       next();
  //     } else {
  //       // console.log('Access denied');
  //       next('/');
  //     }
  //   },
      
  // },
  // {
  //   path: '/payments',
  //   name: 'payment-view',
  //   component: Payment,
  //   meta: { showSpinner: true }
  // },
  {
    path: '/store',
    name: 'store-view',
    component: Store,
    meta: { showSpinner: true }
  },
  {
    path: '/store/form/:id?',
    name: 'store-fomr',
    component: StoreForm,
    meta: { showSpinner: true }
  },
  {
    path: '/invoice/:id?',
    name: 'invoice-view',
    component: Invoice,
    meta: { showSpinner: true }
  },
    // Wildcard route for 404 page
    {
      path: '/:catchAll(.*)',
      name: 'not-found',
      component: NotFound,
    },
];
// This is a navigation guard to restrict access to the /dashboard route

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});




export default router;


