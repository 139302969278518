import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoginForm = _resolveComponent("LoginForm")!
  const _component_CompanyForm = _resolveComponent("CompanyForm")!
  const _component_ForgetPassword = _resolveComponent("ForgetPassword")!
  const _component_ForgetPasswordVerify = _resolveComponent("ForgetPasswordVerify")!
  const _component_ForgetPasswordSet = _resolveComponent("ForgetPasswordSet")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.defaultComponentData=='LoginForm' || !_ctx.defaultComponentData)
      ? (_openBlock(), _createBlock(_component_LoginForm, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.defaultComponentData=='CompanyForm')
      ? (_openBlock(), _createBlock(_component_CompanyForm, { key: 1 }))
      : _createCommentVNode("", true),
    (_ctx.defaultComponentData=='ForgetPasswordForm')
      ? (_openBlock(), _createBlock(_component_ForgetPassword, { key: 2 }))
      : _createCommentVNode("", true),
    (_ctx.defaultComponentData=='ForgetPasswordVerifyForm')
      ? (_openBlock(), _createBlock(_component_ForgetPasswordVerify, { key: 3 }))
      : _createCommentVNode("", true),
    (_ctx.defaultComponentData=='ForgetPasswordSetForm')
      ? (_openBlock(), _createBlock(_component_ForgetPasswordSet, { key: 4 }))
      : _createCommentVNode("", true)
  ], 64))
}