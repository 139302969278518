<template>
  <div>
    <!-- search form start  -->
    <div class="d-flex justify-content-center align-items-center" v-if="loading">
      <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div class="row" v-else>
        <div class="col-lg-3 col-md-6 col-sm-12 mb-4" v-for="(dataItem) in fettchDataArray" :key="dataItem.id">
                <div class="card">
                  <div class="card-body">
                    <div class="d-flex justify-content-center align-items-center" v-if="loading">
                      <div class="spinner-border text-primary" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </div>
                    <div v-else>
                      <h3 class="card-title mb-2 alert-primary p-2 d-flex justify-content-between rounded" >{{ dataItem.title }} <span>
                        <router-link class="me-0 btn btn-sm mb-0 p-1" :to="{ name: 'features-form', params: { id: dataItem.id.toString() } }">
                          <font-awesome-icon :icon="['fas', 'pen-to-square']" />
                        </router-link>
                        <button type="button" class="me-0 btn btn-sm p-1" 
                          data-bs-toggle="modal" data-bs-target="#modalCenter"
                          @click="fetchItem(dataItem)"
                        >
                          <font-awesome-icon :icon="['fas', 'trash-can']" />
                        </button>
                      </span></h3>
                      <p class="card-text mb-0" v-for="(dataItemchildren) in dataItem.children" :key="dataItemchildren.id"> {{ dataItemchildren.title }}
                        <span>
                        <router-link class="me-0 btn btn-sm p-1" :to="{ name: 'features-form', params: { id: dataItemchildren.id.toString() } }">
                          <font-awesome-icon :icon="['fas', 'pen-to-square']" />
                        </router-link>
                        <button type="button" class="me-0 btn btn-sm p-1" 
                          data-bs-toggle="modal" data-bs-target="#modalCenter"
                          @click="fetchItem(dataItem)"
                        >
                          <font-awesome-icon :icon="['fas', 'trash-can']" />
                        </button>
                      </span>

                      </p>
                      <!-- <small class="text-success fw-semibold"
                        ><i class="bx bx-up-arrow-alt"></i> +72.80%</small
                      > -->
                      <!-- <button type="button" class="me-2 btn btn-sm btn-warning" @click="editItem(dataItem.id.toString())"> -->
                      
                    </div>
                  </div>
                </div>
        </div>
    </div>
    <!-- table start  -->
    <!-- // table end -->
    <!-- pagination start  -->

    <!-- // pagination end -->
    <!-- Delete Confirm Modal -->
    <div class="modal fade" id="modalCenter" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header ">
            <h5 class="modal-title text-center" id="modalCenterTitle">Delete Confirmation !</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body text-center">  
            <i class="bx bx-bell bx-lg text-danger mb-3 d-inline-block"></i>      
            <p>Are You Sure to Delete ?</p>
            <!-- <p><b>{{ deletevalue.name }}</b> Page !</p> -->
          </div>
          <div class="modal-footer text-center justify-content-center">
            <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">
              Close
            </button>
            <button data-bs-dismiss="modal" type="button" class="btn btn-danger" @click = "deleteItem(deletevalue.id)">Confirm</button>
          </div>
        </div>
      </div>
    </div>
    <!-- modal end  -->
  </div>
</template>
<script lang="ts">
import { defineComponent, ref } from 'vue';
import axios from '../../../../node_modules/axios/index';
import { useStore } from 'vuex'; // Import useStore from Vuex
import { DOAMIN_PATH } from "@/api";
interface mappingData {
  id: number,
  parent_id: number,
  title : string,
  description: string,
  status: number,
  created_at: string,
  updated_at: string,
  children: [
    {
      id: number,
      parent_id: number,
      title : string,
      description: string,
      status: number,
      created_at: string,
      updated_at: string
    }
  ]
  user: {
    id: number,
    type: string,
    firstname: string,
    lastname: string
  }
}
export default defineComponent({
  name: "feature-list", // Rename the component to a multi-word name
  data() {
    const store = useStore();
    const token = store.state.auth.token || localStorage.getItem('token');
    const storeId =  store.state.auth.storeId || localStorage.getItem('storeId');
    const typeId = store.state.auth.typeId || localStorage.getItem('typeId');
    let userData = store.state.auth.userData || localStorage.getItem('userData');
    userData = JSON.parse(userData);
    return {      
      store,
      token,
      storeId,
      typeId,
      userData: userData,
      loading: false,
      fettchDataArray: ref([] as unknown as mappingData[]), // Explicitly define the type as Post[] // Explicitly define the type as Post[]
      deletevalue: {
        id: 0,
        name: "",
      },
      isDataTrueList: true,
      id: '',
      pageSize: 10, // set a default value
      currentPage: 1, // set a default value
      paginationData: {
        links: [
          {
            label: '',
            url: '',
            active: '',
          }
        ],
        prev_page: 0,
        next_page: 0,
        total: 0,
        per_page: 0,
        current_page: 0,
        last_page: 0,

      }, // Object to store pagination data
      
    };
  },
  methods: {
    async fetchAllFeatureData() {
    try {
      this.loading = true;
      // Update the axios request to include page and size parameters
      const response = await axios.get(
        DOAMIN_PATH + 'feature',
        {
          params: {
            page: this.currentPage,
            size: this.pageSize,
            // type_id: this.storeId
            // TypeId: this.typeId,
          },
          headers: {
            Authorization: `Bearer ${this.token}`,
            // StoreId: this.storeId,
            // TypeId: 9,
            // TypeId: this.typeId ? this.typeId : '',
          },
        }
      );

      if (response.status === 200) {
        this.fettchDataArray = response.data.data;
        this.paginationData = response.data.data;
        this.loading = false;
      }
    } catch (error) {
      console.error(error);
      this.loading = false;
    }
  },
  // Add a method to fetch data when the page size changes
  async fetchData() {
    // Optionally, you can reset the current page to 1 when the page size changes
    // this.currentPage = 1;
    // await this.fetchAllFeatureData();
  },
  goToPage(pageOrLabel: string | number | null) {
    
  if (pageOrLabel === "Next »" || pageOrLabel === "&laquo; Previous") {
    console.log('Skipping Next/Previous');
    return;
  }
  if (typeof pageOrLabel === 'number') {
    console.log('Setting currentPage  :', pageOrLabel);
    this.currentPage = pageOrLabel;
    console.log('curentPage:', this.currentPage);
    // this.fetchAllFeatureData();
  } else if (pageOrLabel) {
    const match = pageOrLabel.match(/page=(\d+)/);
    if (match) {
      console.log('else id match:', parseInt(match[1]));
      this.currentPage = parseInt(match[1]);
    }
  }

  // this.fetchAllFeatureData();
},


    // Add methods to navigate to the previous and next pages
    previousPage() {
      if (this.paginationData.current_page > 1) {
        this.currentPage = this.paginationData.current_page - 1;
        // this.fetchAllFeatureData();
      }
    },

    nextPage() {
      if (this.paginationData.current_page < this.paginationData.last_page) {
        this.currentPage = this.paginationData.current_page + 1;
        // this.fetchAllFeatureData();
      }
    },
     // Delete areas data
    async fetchItem(item: any) {
      this.deletevalue = item;
      console.log("deletevalue", this.deletevalue);
    },
    async deleteItem(id: number) {
      try {
        const response = await (axios as any).delete('https://pos-api.phicsart.com/api/feature/'+id, {
            headers: {
              Authorization: `Bearer ${this.token}`,
            },
          });

        if (response.status == 200) {
          console.log('_delete_success', response);
          // this.fetchAllFeatureData();
        }
      } catch (error) {
        console.error(error);
      }
    },

    sendData(id: string) {
      this.$emit('data-emitted', {
        isDataTrueList: this.isDataTrueList,
        id,
      });
    },
    editItem(id: string) {
      // Navigate to the edit route with the ID parameter
      this.$router.push({ params: { id } });
    },
    
  },
  mounted() {
    this.fetchAllFeatureData();
  },

});
</script>
