<template>
  <div>    
    <!-- <div v-if="!$store.state.auth.token && !isAuthenticated">
      <LoginView/> 
    </div>
    <div v-else>
      <HomePage />
    </div> -->
    <!-- <div v-if="!$store.state.auth.token && !isAuthenticated && userData === null && companyData === null" :userData="userData"> -->
    <div v-if="userData === null">
    <!-- <div v-if="companyData === null || userData === null"> -->
      <!-- <LoginView /> -->
      
      <div v-if="paramsurl === '/warranty'">
        <WarrantySetting />
      </div>
      <div v-else><AuthPage/></div>
    </div>
    <div v-else-if="!isAuthenticated">
      <AuthPage />
    </div>
    <!-- if the url is Warranty then show only the warranty setting component -->
      <!-- <div v-if="paramsurl === '/warranty'"> -->
    <div v-else>
      <HomePage/>
      <!-- <CompanyRegistration /> -->
    </div>

  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useStore } from 'vuex'; // Import useStore from Vuex
//import SidebarView from './views/SidebarView.vue';

import CompanyRegistration from './views/CompanyRegistration/CompanyRegistration.vue';
import HomePage from './views/HomePage/HomePageView.vue';
// import Dashboard from './views/Dashboard/DashboardView.vue';
import AuthPage from './views/AuthPage/AuthPageView.vue';
import axios from 'axios';
import Dashboard from './views/Dashboard/DashboardView.vue';
import WarrantySetting from './views/Warranty/WarrantySetting.vue';
import { useRoute } from 'vue-router';
import { ref, watch } from 'vue';
export default defineComponent({
  name: 'App',
  components: {
    AuthPage,
    // LoginView,
    //SidebarView,
    // CompanyRegistration,
    HomePage,
    WarrantySetting, 
    // Dashboard
  },
  setup() { 
    const store = useStore(); // Use the Vuex store
    const isAuthenticated =  localStorage.getItem('token');
    const userData = localStorage.getItem('userData'); // Parse the JSON string
    const storeData = localStorage.getItem('defaultStoreData');
    const $route = useRoute();
      // Initialize paramsurl with the current path
    const paramsurl = ref($route.path);

  // Watch for changes in the route path and update paramsurl
    watch(
      () => $route.path,
      (newPath) => {
        paramsurl.value = newPath;
      }
    );

    return {
      // login,
      isAuthenticated,
      $store: store,
      userData: userData,
      storeData: storeData,
      paramsurl,
    };
  },
  mounted() {
    const store = useStore(); // Use the Vuex store
    const userData = store.state.auth.userData || localStorage.getItem('userData');
    const storeData = store.state.auth.storeData || localStorage.getItem('defaultStoreData');
    // console.log('userData_App_view_user', userData);
    // console.log('userData_App_view_store', storeData);
    // console.log('paramsurl', this.paramsurl);
  }
});
</script>