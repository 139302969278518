<template >
  <div> 
    <div>
      <VarifyForm v-if="is_varify" @update-link="updateLink" /> 
    </div>

    <div>
      <Links v-if="is_link" @update-form="updateForm" />
    </div>
    <div>
      <CustomerForm v-if="is_customer" @update-status="updateStatus" />
    </div>
    <div>
      <ClaimForm v-if="is_claim" @update-status="updateStatus"/>
    </div>
    <div>
      <Status v-if="is_status" />
    </div>

  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import VarifyForm from "./Components/VarifyForm.vue";
import Links from "./Components/Links.vue";
import CustomerForm from "./Components/CustomerForm.vue";
import ClaimForm from "./Components/ClaimForm.vue";
import Status from "./Components/Status.vue";
export default defineComponent({
  name: "warranty-setting",
  components: { VarifyForm, Links, CustomerForm, ClaimForm, Status },
  data() {
    // const store = useStore();
    // let userData = store.state.auth.userData || localStorage.getItem('userData');
    // userData = JSON.parse(userData);
    return {
      is_varify: ref(true),
      is_link: ref(false),
      is_customer: ref(false),
      is_claim: ref(false),
      is_status: ref(false),
    };
  },
  watch: {

  },
  methods: {
    toggleComponent() {
      this.is_varify = !this.is_varify; // Toggles the visibility
      // if(this.$route.params.id){
      //   this.listShow = !this.listShow;
      //   this.summary = !this.summary;
      //   router.push({ name: "stock-view" });
      // }
    },

    updateLink() {
      this.is_link = true;
      this.is_varify = false;
    },
    updateForm(statusForm: any) {
      // this.is_customer = customerForm;
      // this.is_claim = claimForm;
      console.log('statusForm', statusForm);
      this.is_link = false;
      if (statusForm === 'customer') {
        this.is_customer = true;
        this.is_claim = false;        
      }
      else if(statusForm === 'claim') {
        this.is_customer = false;
        this.is_claim = true;
      }
    },
    updateStatus() {
      this.is_status = true;
      this.is_customer = false;
      this.is_claim = false;
    }

  },

  mounted() {
    // Set isLoading to true when the component is mounted
  }
});
</script>