<template >
  <div class="layout-page">
    <!-- <TopBarView /> -->
    <div class="content-wrapper">
      <!-- <Search /> -->
      <!-- Content -->
      <div>
        <div>
          <div class="flex-grow-1 container-p-y px-4">
            <div class="col-md-9 col-sm-12 mx-auto">
              <div class="row h-100">
                <div class="col-md-6 mx-auto">
                  <div class="h-100">
                    <div class="d-flex align-items-center justify-content-center h-100">
                      <div class="w-100">
                        <div class="authentication-wrapper authentication-basic container-p-y">
                          <div class="authentication-inner w-100">
                            <!-- login -->
                            <div class="card">
                              <div class="card-body">
                                <!-- Logo -->
                                <div class="app-brand justify-content-center">
                                  <a href="/" class="app-brand-link gap-2">
                                    <span class="app-brand-text demo text-body fw-bold text-uppercase mb-3"> 
                                      <img src="https://pos-file.phicsart.com/profile_user.png" alt="n/a" class="img-fluid" style="width:80px; height:80px;">
                                    </span>
                                  </a>
                                </div>
                                <!-- /Logo -->
                                <h4 class="mb-4 text-center">Raida Trade International &mdash; Customer Info 👋</h4>
                                <div v-if="errorMessages.message" class="alert alert-danger alert-dismissible" role="alert">
                                  {{ errorMessages.message }}
                                  <button type="button" class="btn-close" aria-label="Close"></button>
                                </div>
                                <form id="formAuthentication" class="mb-3">
                                  <div class="mb-3">
                                    <h1> Updating Customer ...</h1>
                                    <!-- <label for="firstname" class="form-label">Scan Barcode <sup class="text-danger">*</sup></label> -->
                                    <!-- <input type="text" class="form-control" id="firstname" name="email-username" placeholder="Enter your " > -->
                                  </div>
                                  <!-- <div class="mb-3">
                                    <input type="text" class="form-control" id="lastname" name="email-username" placeholder="Code!">
                                  </div> -->
                                  <!-- <div class="mb-3">
                                    <div class="row">
                                      <div class="col-6">
                                        <button type="submit" class="btn btn-primary w-100 justify-content-center" :disabled="submitBtn">
                                          <span v-if="!submitBtn">Varify Code</span> 
                                          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="submitBtn"></span>
                                          <span class="visually-show" v-if="submitBtn"> Varifying ....</span>
                                        </button>   
                                      </div>
                                    </div>
                                  </div> -->
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>  
          </div>
        </div>
      </div>
      <!-- <Footer /> -->
      <!-- / Footer -->

      <div class="content-backdrop fade"></div>
    </div>
    <!-- / Layout wrapper -->
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
// import Footer from "../FooterView.vue";
// import TopBarView from "../TopBarView.vue";
// import { useStore } from "vuex";
export default defineComponent({
  // components: { Footer, TopBarView},
  name: "cutomer-form",
  data() {
    // const store = useStore();
    // let userData = store.state.auth.userData || localStorage.getItem('userData');
    // userData = JSON.parse(userData);
    return {
      errorMessages: ref({ message: '' }),
      submitBtn: ref(false),
    };
  },
  watch: {

  },
  methods: {

  },

  mounted() {
    // Set isLoading to true when the component is mounted
  }
});
</script>