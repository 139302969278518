<template>
    <div>
      <!-- search form start  -->
      <div class="card mb-4">
        <div class="card-body">    
          <div class="row">
            <div class="input-group">
              <!-- <div class="input-group-prepend">
                <select class="form-select" aria-label="Default select example">
                      <option selected>Search By-</option>
                      <option value="1">Category</option>
                      <option value="2">Product Name </option>
                </select>  
              </div> -->
              <input type="text" class="form-control w-25" placeholder="Enter Search Key !">
              <!-- <div class="input-group"> -->
                  <!-- <span class="input-group-text" id="basic-addon1">From</span>
                  <input type="date" class="form-control" placeholder="Enter Search Key !">
                  <span class="input-group-text" id="basic-addon1">To</span>
                  <input type="date" class="form-control" placeholder="Enter Search Key !"> -->
              <!-- </div> -->
              <button type="button" class="btn btn-primary me-1 ms-1"><font-awesome-icon :icon="['fas', 'magnifying-glass']" /></button>
              <button type="button" class="btn btn-default border"><font-awesome-icon :icon="['fas', 'broom']" /></button>               
            </div>
          </div>
        </div>
      </div>
      <!-- search form end  -->
      <!-- table start  -->
      <div class="card mb-4 d-none d-md-block">
        <div class="card-body">
          <div class="d-flex justify-content-center align-items-center" v-if="loading">
            <div class="spinner-border text-primary" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
          <div class="table-responsive" v-else>
            <table class="table card-table text-center" v-if="fettchDataArray.length > 0">
            <thead>
                <tr>
                <th>Id</th>
                <!-- <th v-if="userData?.type=='SUPERADMIN'">Company</th> -->
                <th>Code</th>
                <th>Barcode</th>
                <!-- <th>Quantity (WH | DE)</th> -->
                <th>Status</th>
                <th>Action</th>
                </tr>
            </thead>
            <tbody class="table-border-bottom-0">
                <tr v-for="(dataItem, index) in fettchDataArray" :key="dataItem.id">
                <td>{{ index + 1 }}</td>
                <!-- <td v-if="userData?.type=='SUPERADMIN'">{{ dataItem.store?.name }}</td> -->
                <td>{{ dataItem.barcode }}</td>
                <td><svg :ref="el => barcodeRefs[dataItem.id] = el" style="width: 30px; height: 10px;"></svg></td>
                <td>{{ dataItem.status }}</td>
                <td>
                    <button type="button" class="me-2 btn btn-sm btn-primary btn-disable" data-bs-toggle="modal" data-bs-target="#modalCenter"> Option </button>
                    <!-- <button type="button" class="me-2 btn btn-sm btn-secondary btn-disable"> Summary </button> -->
                </td>
                </tr>
            </tbody>
            </table>
            <div v-else>
              <h5 class="text-center mb-0">Please Add Some Product</h5>
            </div>
          </div>
        </div> 
      </div>
      <!-- // table end -->
      <!-- pagination start  -->
      <div class="card"  v-if="fettchDataArray.length > 0">
        <div class="card-body d-flex justify-content-around flex-wrap">
          <nav aria-label="Page navigation">
            <ul class="pagination flex-wrap">
              <!-- Previous button -->
              <!-- <li class="page-item" @click="previousPage">
                <a class="page-link"><i class="tf-icon bx bx-chevron-left"></i></a>
              </li> -->
  
              <!-- Page buttons -->
                <li v-for="(page, index) in paginationData.links" :key="index" class="page-item myPagination m-1" :class="{ active: page.active }">
                  <button class="page-link" @click="goToPage(Number(page.label))" v-html="page.label"></button>
                </li>
              <!-- Next button -->
              <!-- <li class="page-item" @click="nextPage">
                <a class="page-link"><i class="tf-icon bx bx-chevron-right"></i></a>
              </li> -->
            </ul>
          </nav>
          <div class="row">
            <div class="col-12">
              <div class="input-group ">
                <label class="input-group-text" for="inputGroupSelect01">Size</label>
                <select class="form-select" id="inputGroupSelect01" v-model="pageSize" @change="fetchData">
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="30">30</option>
                  <option value="40">40</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>
            </div>
          </div>
        </div> 
      </div>
      <!-- // pagination end -->

      <!-- modal end  -->
    </div>
  </template>
  <script lang="ts">
  import { defineComponent, ref, nextTick } from 'vue';
  import axios from '../../../../node_modules/axios/index';
  import { useStore } from 'vuex'; // Import useStore from Vuex
  import { DOAMIN_PATH } from "@/api";
  import JsBarcode from 'jsbarcode';
  interface mappingData {
    id: number;
    barcode: string;
    remarks: string;
    status: number;
    product_id: number;
    stock_id: number;
    user_id: number;
  }
  
  export default defineComponent({
    name: "BarcodeList", // Rename the component to a multi-word name
    data() {
      return {
        fettchDataArray: ref([] as unknown as mappingData[]),
        paginationData: {
        links: [
          {
            label: '',
            url: '',
            active: '',
          }
        ],
        prev_page: 0,
        next_page: 0,
        total: 0,
        per_page: 0,
        current_page: 0,
        last_page: 0,
        successReport: false
      },
        pageSize: 10,
        loading: false,
        successReport: false,
        currentPage: 1,
        token: localStorage.getItem('token'),
        barcodeRefs: {} as { [key: number]: SVGSVGElement | null },
      };
    },
    methods: {
      async fetchData() {
        try {
          this.loading = true;
          const response = await (axios as any).get(DOAMIN_PATH + 'barcode',
            {
              headers: {
                Authorization: `Bearer ${this.token}`,
              }
            }
          );
          if (response.status == 200) {
            this.fettchDataArray = response.data.data;
            this.paginationData = response.data.data;
            this.loading = false;
            nextTick(() => {
            this.generateBarcodes();
            });
          }
        } catch (error) {
          console.error(error);
        }
    },
      goToPage(pageOrLabel: string | number | null) {
          
        if (pageOrLabel === "Next »" || pageOrLabel === "&laquo; Previous") {
          console.log('Skipping Next/Previous');
          return;
        }
        if (typeof pageOrLabel === 'number') {
          console.log('Setting currentPage  :', pageOrLabel);
          this.currentPage = pageOrLabel;
          console.log('curentPage:', this.currentPage);
          // this.fetchAllData();
        } else if (pageOrLabel) {
          const match = pageOrLabel.match(/page=(\d+)/);
          if (match) {
            console.log('else id match:', parseInt(match[1]));
            this.currentPage = parseInt(match[1]);
          }
        }
        this.fetchData();
      },

      // // Add methods to navigate to the previous and next pages
      previousPage() {
        if (this.paginationData.current_page > 1) {
          this.currentPage = this.paginationData.current_page - 1;
          this.fetchData();
        }
      },

      nextPage() {
        if (this.paginationData.current_page < this.paginationData.last_page) {
          this.currentPage = this.paginationData.current_page + 1;
          this.fetchData();
        }
      },
      async handleSubmit() {
      try {
          const response = await (axios as any).post(DOAMIN_PATH + 'stock',
            {
              headers: {
                Authorization: `Bearer ${this.token}`,
              }
            }
          );
          if (response.status == 201) {
            const closeButton = document.getElementById('close');
            if (closeButton !== null) {
              closeButton.click();
            }
            this.successReport = true;
            this.fetchData();
            setTimeout(() => { this.successReport = false;}, 3000);
          }
      } catch (error) {
        console.error(error);
      }
    },
     async generateBarcodes() {
      this.fettchDataArray.forEach((dataItem) => {
        const svgElement = this.barcodeRefs[dataItem.id];
        if (svgElement && dataItem.barcode) {
          // Explicitly cast to SVGSVGElement
          JsBarcode(svgElement as SVGSVGElement, dataItem.barcode, {
            format: 'CODE128',
            lineColor: '#000',
            width: 1,
            height: 30,
            displayValue: true,
          });
          console.log('_svgElement', svgElement);
        }
      });
    },
    },
    mounted() {
      this.fetchData(); 
    },
  });
  </script>