<template>
  <div>
    <div v-if="successReport" class="alert alert-success alert-dismissible" role="alert">
     Successfully
     <button type="button" class="btn-close"  aria-label="Close"></button>
   </div>
    <!-- search form start  -->
    <div class="card mb-4">
      <div class="card-body">    
        <div class="row">
          <div class="input-group">
            <div class="input-group-prepend">
              <!-- <select class="form-select" aria-label="Default select example">
                    <option selected>Search By-</option>
                    <option value="1">Category</option>
                    <option value="2">Product Name </option>
              </select>   -->
            </div>
            <input type="text" class="form-control w-25" placeholder="Enter Search Key !">
            <!-- <div class="input-group"> -->
                <!-- <span class="input-group-text" id="basic-addon1">From</span>
                <input type="date" class="form-control" placeholder="Enter Search Key !">
                <span class="input-group-text" id="basic-addon1">To</span>
                <input type="date" class="form-control" placeholder="Enter Search Key !"> -->
            <!-- </div> -->
            <button type="button" class="btn btn-primary me-1 ms-1"><font-awesome-icon :icon="['fas', 'magnifying-glass']" /></button>
            <button type="button" class="btn btn-default border"><font-awesome-icon :icon="['fas', 'broom']" /></button>            
          </div>
        </div>
      </div>
    </div>
    <!-- search form end  -->
    <!-- table start  -->
    <div class="card mb-4 d-none d-md-block">
      <div class="card-body">
        <div class="d-flex justify-content-center align-items-center" v-if="loading">
          <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <div class="table-responsive" v-else>
          <table class="table card-table text-center" v-if="fettchDataArray.length > 0">
            <thead>
              <tr>
                <th>Id</th>
                <!-- <th v-if="userData?.type=='SUPERADMIN'">Company</th> -->
                <th>Name</th>
                <!-- <th>Quantity (WH | DE)</th> -->
                <th>Unit</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody class="table-border-bottom-0">
              <tr v-for="(dataItem, index) in fettchDataArray" :key="dataItem.id">
                <td>{{ index + 1 }}</td>
                <!-- <td v-if="userData?.type=='SUPERADMIN'">{{ dataItem.store?.name }}</td> -->
                <td>{{ dataItem.name }} {{ dataItem.id }}</td>
                <!-- <td> -->
                  <!-- <span v-if="dataItem.stocks[0]?.total_quantity ">{{ dataItem.stocks[0]?.total_quantity }} </span> -->
                  <!-- <span v-else>00</span> -->
                <!-- </td> -->
                <!-- <td>{{ dataItem.alert_quantity }}</td> -->
                <td>{{ dataItem.unit }}</td>
                <td>
                  <button type="button" class="me-2 btn btn-sm btn-primary btn-disable" @click="fetchItem(dataItem)" data-bs-toggle="modal" data-bs-target="#modalCenter"> + Add Stock </button>
                  <button type="button" class="me-2 btn btn-sm btn-secondary btn-disable" @click="summaryFunc(dataItem.id.toString())"> Summary </button>
                </td>
              </tr>
            </tbody>
          </table>
          <div v-else>
            <h5 class="text-center mb-0">Please Add Some Product First</h5>
          </div>
        </div>
      </div> 
    </div>
    <!-- // table end -->
    <div class="mobile-view d-block d-md-none">
      <div class=" card" v-if="loading">
        <div class="card-body">
          <div class="d-flex justify-content-center align-items-center" >
            <div class="spinner-border text-primary" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      </div>
      <div v-if="fettchDataArray.length > 0">
        <div class="card mb-4" v-for="dataItem in fettchDataArray" :key="dataItem.id">
          <div class="card-body">
          <!-- <span>{{index+1}}</span><br> -->
          
          <strong> Name: {{ dataItem.name }}</strong><br/>
          <!-- <p class="mb-0">Category: {{ dataItem.category.name}}</p> -->
          <!-- <p>Quantity: 
            <span v-if="dataItem.stocks[0]?.total_quantity">{{ dataItem?.stocks[0]?.total_quantity }} </span>
            <span v-else>00</span>
          </p> -->
            <button type="button" class="me-2 btn btn-sm btn-primary btn-disable" @click="fetchItem(dataItem)" data-bs-toggle="modal" data-bs-target="#modalCenter"> + Add Stock </button>
            
            <button type="button" class="me-2 btn btn-sm btn-secondary btn-disable" @click="summaryFunc(dataItem.id.toString())"> Summary </button>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="card">
          <div class="card-body">
            <h5 class="text-center mb-0">Please Add Some Product First</h5>
          </div>
        </div>
      </div>
    </div>
    <!-- pagination start  -->
    <div class="card" v-if="fettchDataArray.length > 0">
      <div class="card-body d-flex justify-content-around flex-wrap">
        <nav aria-label="Page navigation">
          <ul class="pagination flex-wrap">
            <!-- Previous button -->
            <!-- <li class="page-item" @click="previousPage">
              <a class="page-link"><i class="tf-icon bx bx-chevron-left"></i></a>
            </li> -->

            <!-- Page buttons -->
              <li v-for="(page, index) in paginationData.links" :key="index" class="page-item myPagination m-1" :class="{ active: page.active }">
                <button class="page-link" @click="goToPage(Number(page.label))" v-html="page.label"></button>
              </li>
            <!-- Next button -->
            <!-- <li class="page-item" @click="nextPage">
              <a class="page-link"><i class="tf-icon bx bx-chevron-right"></i></a>
            </li> -->
          </ul>
        </nav>
        <div class="row">
          <div class="col-12">
            <div class="input-group ">
              <label class="input-group-text" for="inputGroupSelect01">Size</label>
              <select class="form-select" id="inputGroupSelect01" v-model="pageSize" @change="fetchData">
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="30">30</option>
                <option value="40">40</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </div>
          </div>
        </div>
      </div> 
    </div>
    <!-- // pagination end -->
    <!-- Delete Confirm Modal -->
    <div class="modal fade" id="modalCenter" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header ">
            <h5 class="modal-title text-center" id="modalCenterTitle">Add Stock</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <form name="" @submit.prevent="handleSubmit">
          <div class="modal-body">
            <div class="row mb-3">
              <label
                class="col-sm-12 col-form-label"
                for="basic-icon-default-store"
                >Quantity
              </label>
              <div class="col-sm-12">
                  <input type="text" class="form-control" id="basic-icon-default-fullname" placeholder="Quantity" v-model="addstock.quantity" >
              </div>
            </div>          
          </div>
          <div class="modal-footer text-center justify-content-center">
            <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal" id="close">
              Close
            </button>
            <button type="submit" class="btn btn-primary">Confirm</button>
          </div>
           </form> 
        </div>
      </div>
    </div>
    <!-- modal end  -->
  </div>
</template>
<script lang="ts">
import { defineComponent, ref } from 'vue';
import axios from '../../../../node_modules/axios/index';
import { useStore } from 'vuex'; // Import useStore from Vuex
import { DOAMIN_PATH } from "@/api";
interface mappingData {
    id: number;
    name: string;
    description: string;
    thumb: string | null;
    buy_price: number;
    wholesale_price: number;
    sell_price: number;
    unit: string;
    alert_quantity: number;
    features: string | null;
    user_id: number | null;
    created_at: string;
    updated_at: string;
  // in_stock: number,
}
export default defineComponent({
  name: "CategoryList", // Rename the component to a multi-word name
  data() {
    const store = useStore();
    const token = store.state.auth.token || localStorage.getItem('token');
    let userData = store.state.auth.userData || localStorage.getItem('userData');
    userData = JSON.parse(userData);
    const userId = store.state.auth.userId || localStorage.getItem('userId');
    const storeId =  store.state.auth.storeId || localStorage.getItem('storeId');
    return {      
      store,
      token,
      userData: userData,
      userId,
      storeId,
      successReport: false,
      fettchDataArray: ref([] as unknown as mappingData[]), // Explicitly define the type as Post[] // Explicitly define the type as Post[]
      deletevalue: {
        id: 0,
        name: "",
      },
      isDataTrueList: true,
      id: '',
      loading: false, 
      pageSize: 10, // set a default value
      currentPage: 1, // set a default value
      paginationData: {
        links: [
          {
            label: '',
            url: '',
            active: '',
          }
        ],
        prev_page: 0,
        next_page: 0,
        total: 0,
        per_page: 0,
        current_page: 0,
        last_page: 0,

      }, // Object to store pagination data
      addstock: {
        id: 0,
        type: "IN",
        quantity: 0,
        store_id: "",
        product_id: 0,
        user_id: 0,
        supplier_id: "",
      },
    };
  },
  methods: {
    async fetchAllData() {
      try {
        this.loading = true;
        if (!this.token) {
            throw new Error('Token not found');
          }
          const response = await axios.get(DOAMIN_PATH + 'product',
          {
            params: {
              page: this.currentPage,
              size: this.pageSize,
            },
            headers: {
              Authorization: `Bearer ${this.token}`, 
              userId: this.userId,
            }
          }
          );
        if (response.status === 200) {
          this.fettchDataArray = response.data.data.data;
          this.paginationData = response.data.data;
          console.log('this.fettchDataArray', this.fettchDataArray);
          this.loading = false;
        }
      } catch (error) {
        console.error(error);      
        this.loading = false;
      }
    },

    // Delete areas data
    async fetchItem(item: any) {
      this.addstock = item;
      console.log("deletevalue", this.deletevalue);
    },
   

    summaryFunc(id: any) {
      // Navigate to the edit route with the ID parameter
      this.$router.push({params: { id } });
    },
    async fetchData() {
    // Optionally, you can reset the current page to 1 when the page size changes
    // this.currentPage = 1;
      // await this.fetchAllData();
    },
      goToPage(pageOrLabel: string | number | null) {
        
      if (pageOrLabel === "Next »" || pageOrLabel === "&laquo; Previous") {
        console.log('Skipping Next/Previous');
        return;
      }
      if (typeof pageOrLabel === 'number') {
        console.log('Setting currentPage  :', pageOrLabel);
        this.currentPage = pageOrLabel;
        console.log('curentPage:', this.currentPage);
        // this.fetchAllData();
      } else if (pageOrLabel) {
        const match = pageOrLabel.match(/page=(\d+)/);
        if (match) {
          console.log('else id match:', parseInt(match[1]));
          this.currentPage = parseInt(match[1]);
        }
      }
      this.fetchAllData();
    },

    // // Add methods to navigate to the previous and next pages
    previousPage() {
      if (this.paginationData.current_page > 1) {
        this.currentPage = this.paginationData.current_page - 1;
        this.fetchAllData();
      }
    },

    nextPage() {
      if (this.paginationData.current_page < this.paginationData.last_page) {
        this.currentPage = this.paginationData.current_page + 1;
        this.fetchAllData();
      }
    },

    async handleSubmit() {
      try {
          this.addstock.user_id = this.userId;
          this.addstock.product_id = this.addstock.id;
          this.addstock.type = "IN";
          const response = await (axios as any).post(DOAMIN_PATH + 'stock', 
          this.addstock,
            {
              headers: {
                Authorization: `Bearer ${this.token}`,
              }
            }
          );
          if (response.status == 201) {
            const closeButton = document.getElementById('close');
            if (closeButton !== null) {
              closeButton.click();
            }
            this.addstock = {
              id: 0,
              type: 'IN', 
              quantity: 0,
              product_id: 0,
              supplier_id:'',
              user_id: this.userId,
              store_id: this.storeId,
            };
            this.successReport = true;
            this.fetchAllData();
            setTimeout(() => { this.successReport = false;}, 3000);
          }
      } catch (error) {
        console.error(error);
      }
    }
  },
  mounted() {
    this.fetchAllData();
    console.log('__fettchDataArray', this.fettchDataArray);
  },
});
</script>